import React, { useEffect, useRef } from "react";
import { Parallax, Background } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/NavbarReveal';
import Hero from '../section/hero';
import About from '../section/about';
import Gallery from '../section/gallery';
import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { GlobalStyles } from './style/video';


export default function Home() {
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax strength={300}>
            <img className='image-hero' src="./img/gallery/hero1.jpg" alt="gallery" />
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition mboile-view" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* ABOUT */}
        <section id="about" className="pb-0">
          <About />
        </section>

        {/* Gallery */}
        <section id="gallery">
          <Gallery />
        </section>

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <a style={{ color: '#E3311D' }} href="https://www.facebook.com/FreshLeo333" target="_blank" rel="noreferrer"><i className="fa fa-facebook fa-lg"></i></a>
            <a style={{ color: '#E3311D' }} href="https://www.instagram.com/leo_fresh_tattoo?igsh=aXR2cTBkN2NiaTd0&utm_source=qr" target="_blank" rel="noreferrer"><i className="fa fa-instagram fa-lg"></i></a>
            {/* <a style={{ color: '#E3311D' }} href="https://www.tiktok.com/" target="_blank" rel="noreferrer"><i className="fa fa-tiktok fa-lg"></i></a> */}
          </div>
          <span>Follow  me</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  );
}
